import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalContext } from 'lib/ContextTypes';
import PropTypes from 'prop-types';
import className from 'classnames';
import './styles.themed.scss';
import Link from 'components/Link';
import TypeIcon from 'components/TypeIcon';
import { isBlogLive, isBlogEventEnded } from 'lib/liveBlog';

import { getVerticalName } from 'lib/verticalType';
import { TimeAgo } from 'components/TimeAgo';

export const SpecialTypes = { supported: ['slideshow', 'video', 'shop'] };

function getPackageName(packageClass, isRailAdjacent) {
  const packageSuffix = 'pkg ';
  const packageName = packageClass.replace(packageSuffix, '');

  if (isRailAdjacent) {
    return packageName.concat('--rail-adjacent');
  }

  return packageName;
}

function opinionEyebrow(url = '') {
  const { t } = useTranslation();

  return (
    <h2 className="related-content__eyebrow">
      <Link
        to={url}
        className="related-content__eyebrow-link"
        data-test="related-content__eyebrow"
        data-testid="related-content__eyebrow"
      >
        {t('Opinion')}
      </Link>
    </h2>
  );
}

function headline(type, headlineText, item, url, isLiveBlogActive, displayTimeAgo) {
  const publishedAt = item?.date?.publishedAt;
  const isLiveBlog = type === 'liveBlog';
  return (
    <h3
      className="related-content__headline"
      data-test="related-content__headline"
      data-testid="related-content__headline"
    >
      { displayTimeAgo ? <TimeAgo dateString={publishedAt} displayTime={false} /> : null }
      <Link
        to={url}
        className="related-content__headline-link"
        data-test="related-content__headline__link"
        data-testid="related-content__headline__link"
      >
        {isLiveBlog && (
          <span
            className={className(
              'related-content__headline--liveblog',
              {
                active: isLiveBlogActive,
              },
            )}
          >
            Live Updates&nbsp;/&nbsp;
          </span>
        )}
        {headlineText}
      </Link>
    </h3>
  );
}

function icon(type, primaryVertical, vertical, isLiveBlogActive) {
  const isOpinion = primaryVertical === 'think' && vertical !== 'think';
  const isLiveBlog = type === 'liveBlog';

  const { supported: SupportedSpecialIcons } = SpecialTypes;
  const specializedIcon = SupportedSpecialIcons.includes(type);

  return (
    <TypeIcon
      type={specializedIcon ? type : 'related-article'}
      className={className(
        'related-content__icon',
        {
          'related-content__icon-liveblog': isLiveBlog,
          active: isLiveBlogActive,
          'related-content__icon-gallery': type === 'slideshow',
          'related-content__icon-opinion': isOpinion,
        },
      )}
    />
  );
}

function RelatedContent({
  related,
  isRailAdjacent = false,
  displayTimeAgo = false,
  pkgClassName = null,
  parentNoImage = false,
}) {
  const container = 'relatedContent';
  const vertical = useContext(VerticalContext);
  const formattedPkgName = pkgClassName && pkgClassName.includes('standardLeadPlus')
    ? 'pkg related-content__item-featured--standard-lead'
    : pkgClassName;

  // packages that support 4 stories
  const withinFeatured = [
    'pkg related-content__item-featured--one-up',
    'pkg related-content__item-featured--standard-lead',
    'pkg related-content__item-featured--straight-up',
  ].includes(formattedPkgName);
  const isLeadSectionFront = formattedPkgName === 'pkg related-content__item--lead-section-front';
  const featuredPackage = formattedPkgName && getPackageName(formattedPkgName, isRailAdjacent);

  return !!related.length && (
    <div
      className={className(
        container,
        'related-content',
        {
          'related-content__parent-no-img': parentNoImage,
        },
      )}
      data-test="related-content"
      data-testid="related-content"
    >
      <ul
        className="related-content__list"
        data-test="related-content__list"
        data-testid="related-content__list"
      >
        {
          related.map(
            ({
              computedValues,
              id,
              item,
              type,
            }) => {
              if (!computedValues) return null;

              const headlineText = computedValues?.headline;
              const primaryUrl = computedValues?.unibrow?.url?.primary;
              const url = computedValues?.url;
              const flag = computedValues?.flag;
              const datePublished = item?.datePublished;
              const isLiveBlogActive = type === 'liveBlog' && (isBlogLive(datePublished) && !isBlogEventEnded(flag));

              const primaryVertical = getVerticalName({ item });
              const isOpinion = primaryVertical === 'think';
              const isThink = vertical === 'think';

              return (
                <li
                  key={id}
                  className={className(
                    'related-content__item',
                    {
                      'related-content__item-featured': withinFeatured,
                      [featuredPackage]: withinFeatured || isLeadSectionFront,
                    },
                  )}
                  data-contentid={id}
                  data-test="related-content__list__item"
                  data-testid="related-content__list__item"
                >
                  { icon(type, primaryVertical, vertical, isLiveBlogActive) }
                  { !isThink && isOpinion && opinionEyebrow(primaryUrl) }
                  {
                    headline(type, headlineText, item, url, isLiveBlogActive, displayTimeAgo)
                  }
                </li>
              );
            },
          )
        }
      </ul>
    </div>
  );
}

RelatedContent.contextTypes = {
  vertical: PropTypes.string,
};

RelatedContent.propTypes = {
  isRailAdjacent: PropTypes.bool,
  displayTimeAgo: PropTypes.bool,
  pkgClassName: PropTypes.string,
  parentNoImage: PropTypes.bool,
  related: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};


export default RelatedContent;
