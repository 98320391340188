import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { hasNoPaidContent } from 'redux/modules/front';
import { paidContentList } from 'lib/paidContentUtils';
import Ad from 'components/Ad';

import styles from './styles.module.scss';


export const PaidContent = ({
  type,
  adLoaded = Function.prototype,
  className = null,
  isMSNBCRightRail = false,
}) => {
  const dispatch = useDispatch();

  let Element = 'article';
  let adSlot = 'nativethreeup';

  /*
    nativetopvideolist will render on all
    right rail msnbc alacartes.
    this does not check if it's the first
    to render it. At the moment it's not
    an issue because curators do not
    use multiple alacartes on zone B.
  */
  if (isMSNBCRightRail) {
    adSlot = 'nativetopvideolist';
  }

  if (type === paidContentList) {
    Element = 'li';
    adSlot = 'nativeultradense';
  }

  const adHandler = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event) {
        return;
      }
      if (event.empty) {
        dispatch(hasNoPaidContent());
      }
      adLoaded(true);
    });
    mpsAd.render();
  };
  return (
    <Element className={`${styles.adWrapper} ${className}`}>
      <Ad
        activeTab={false}
        adClass={styles.nativeAdContainer}
        handler={adHandler}
        refreshInterval={0}
        slot={adSlot}
      />
    </Element>
  );
};

PaidContent.propTypes = {
  adLoaded: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  isMSNBCRightRail: PropTypes.bool,
};

