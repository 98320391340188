import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * LeadBorderTop component
 * @param {object} props - Component properties
 * @param {string[]} [props.types=['collectionLead', 'showLead']] - Types of leads
 * @param {string} [props.customStyles] - Custom styles for the component
 */
const LeadBorderTop = ({
  types = ['collectionLead', 'showLead'],
  customStyles,
}) => {
  const typeStyles = types.map((type) => `${type}-border` || '');
  return <div className={classNames(typeStyles, customStyles)} />;
};

LeadBorderTop.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  customStyles: PropTypes.string,
};

export default LeadBorderTop;
