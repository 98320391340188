import React from 'react';
import PropTypes from 'prop-types';
import { package as PackagePropType } from 'lib/CustomPropTypes';

import { PackageTitleSmall } from 'components/PackageTitleSmall';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import { Button } from 'components/Button/';
import { TypeIconInline } from 'components/TypeIconInline/';
import { VerticalContext } from 'lib/ContextTypes';
import { PaidContent } from 'components/PaidContent';
import { getTeaseUrl } from 'lib/teaseUtils';
import { imageAltForItem } from 'lib/imageAlt';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import classNames from 'classnames';

import { Save } from 'components/SocialShareMenu/Save';
import styles from './styles.module.scss';

/**
 * Detect if content item is first in row
 * @param {string} type
 * @param {number} index
 */
const isLast = (type, index) => {
  if (type === 'rail' || type === 'railAdjacent') {
    return index % 3 === 0;
  }
  if (type === 'halfWidth') {
    return index % 2 === 0;
  }
  return index % 4 === 0;
};

/**
 * Detect if content item is last in row
 * @param {string} type
 * @param {number} index
 */
const isFirst = (type, index) => {
  if (index === 1) return true;

  if (type === 'rail' || type === 'railAdjacent') {
    return index % 3 === 1;
  }
  if (type === 'halfWidth') {
    return index % 2 === 1;
  }
  return index % 4 === 1;
};


/**
 * Detect if content item is in the last row
 * @param {string} type
 * @param {number} index
 * @param {number} length
 */
const isLastRow = (type, index, length) => {
  let rowLimit = 4;
  if (type === 'rail' || type === 'railAdjacent') {
    rowLimit = 3;
  } else if (type === 'halfWidth') {
    rowLimit = 2;
  }

  const amtLastRow = length % rowLimit || rowLimit;
  return length <= rowLimit || length - index <= amtLastRow;
};

const FLAVORS = {
  s: AIMS_FLAVORS.FOCAL_276x276,
};

/**
 * TeaseRow package
 * Creates small tease content items as rows of content
 * @param {PackagePropType} content package data from API
 * @param {string} [pkgClassName] optional custom class names for the package section
 * @param {boolean} [isStandalone] determines if this is a standalone package from Curator
 *   or if it is being used as an element within a different package; used for analytics
 * @param {boolean} [isWordSearchGame] determines whether the package is used within WordSearchGame package
 * @returns {React.ReactElement}
 */
const TeaseRow = ({
  content,
  pkgClassName = 'pkg',
  isStandalone = true,
  isWordSearchGame = false,
}) => {
  const vertical = React.useContext(VerticalContext);

  const {
    id,
    subType = 'fullWidth',
    items,
    metadata: {
      title,
      seeAllUrl,
      showTeaseImage,
      seeAllText,
    } = {},
  } = content;

  /**
   * Checks if item content type is a blog card
   * @param {object} item item from package content
   * @returns {boolean}
   */
  const isBlogCard = (item) => item.type === 'card';

  return (
    <section
      className={classNames(
        pkgClassName,
        styles.teaseRow,
        styles[subType],
        'inheritence',
        {
          'teaseRow--noButton': !seeAllUrl,
          [styles.singleItem]: items.length === 1,
          [styles.searchWordGame]: isWordSearchGame,
        },
      )}
      data-test="teaseRow"
      data-testid="teaseRow"
      data-test-subtype={subType}
      data-packageid={isStandalone ? id : null}
      data-activity-map={isStandalone ? 'teaseRow-package' : null}
    >
      { !isWordSearchGame ? (
        <PackageTitleSmall
          title={title}
          classes={styles.title}
        />
      ) : null}
      <div className={styles.wrapper}>
        {items.filter((item) => item).map((item, i) => {
          const imageUrl = getTeaseUrl(item, true);
          const displayImage = !!imageUrl && showTeaseImage !== false;
          const imageAlt = imageAltForItem(item);

          return (
            <div
              key={item.id}
              className={classNames(
                styles.contentItem,
                'teaserow-content-item',
                {
                  [styles.lastInRow]: isLast(subType, i + 1),
                  [styles.firstInRow]: isFirst(subType, i + 1),
                  [styles.lastRow]: isLastRow(subType, i, items.length),
                  [styles.noImg]: !displayImage,
                },
              )}
              data-test="teaserow-content-item"
            >
              {item.type === 'paidContentCard' ? (
                <PaidContent
                  type={item.type}
                  vertical={vertical}
                />
              ) : (
                <>
                  {displayImage && (
                    <div className={styles.teaseWrapper}>
                      <Link
                        to={item.computedValues?.url}
                        data-test="tease-row-image"
                      >
                        <TeasePicture
                          responsiveFlavors={FLAVORS}
                          type={item.type}
                          url={imageUrl}
                          computedValues={item.computedValues}
                          alt={imageAlt}
                        />
                      </Link>
                      { !isWordSearchGame ? (
                        <Save
                          contentId={item.id}
                          contentType={item.type}
                          options={{ isThumbnail: true, offset: isStandalone ? 'm' : null }}
                        />
                      ) : null }
                    </div>
                  )}

                  <div
                    className={classNames(
                      styles.textWrapper,
                      {
                        [styles.noImg]: !displayImage,
                      },
                    )}
                  >
                    { (!isBlogCard(item)) && !isWordSearchGame ? (
                      <Unibrow
                        isPackage
                        unibrow={item.computedValues?.unibrow}
                        hasDefaultTextStyle
                      />
                    ) : null}
                    <Link
                      to={item.computedValues?.url}
                      data-test="tease-row-text"
                    >
                      <h3 className={styles.teaseRowHeadline}>
                        <TypeIconInline
                          className={styles.typeIcon}
                          type={item.type}
                          taxonomy={item?.item?.taxonomy}
                        />
                        {item.computedValues?.headline}
                      </h3>
                    </Link>
                  </div>
                  {!displayImage && !isWordSearchGame && (
                    <Save
                      contentId={item.id}
                      contentType={item.type}
                      options={{ isTitle: true }}
                    />
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>

      {seeAllUrl && (
        <Button
          url={seeAllUrl}
          title={seeAllText}
          alignment="right"
          tiltClasses="tease-row--button"
        />
      )}
    </section>

  );
};

TeaseRow.propTypes = {
  content: PackagePropType.isRequired,
  pkgClassName: PropTypes.string,
  isStandalone: PropTypes.bool,
  isWordSearchGame: PropTypes.bool,
};


export { TeaseRow };
